.grid-color-debug {
  .grid-column,
  .site-wide,
  .site-wide--responsive-padding {
    background-color: rgba(blue, 0.4);
  }
}

/**
 * Add grid layouts in this partial.
 *
 */

// Site-wide is used as a container for the grid or components
.site-wide,
.title-area {
  width: 100%;
  max-width: $site-width;
  margin: 0 auto;
  overflow: visible;
  padding-left: $site-wide-padding;
  padding-right: $site-wide-padding;
}

.grid-container {
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 30px;

    @include mq(laptop-s) {
      grid-column-gap: 30px;
    }
  }

  @supports not (display: grid) {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-between;

    .grid-column {
      display: block;
      box-sizing: border-box;
      max-width: 100%;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 30px;

      @include mq(laptop-s) {
        flex: 0 0 auto;
      }
    }
  }

  + .grid-container {
    margin-top: 30px;
  }

  &.grid-container--half {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        @supports (display: grid) {
          grid-column: span 6;
        }

        @supports not (display: grid) {
          flex: 0 0 calc(50% - 30px);
          padding: 0 15px;
        }
      }
    }
  }

  &.grid-container--4-4-4 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        grid-column: span 4;

        @supports not (display: grid) {
          flex: 0 0 calc(33.333% - 30px);
          padding: 0 15px;
        }
      }
    }
  }

  &.grid-container--4-8 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 1 / span 4;

          @supports not (display: grid) {
            flex: 0 0 33.333%;
          }
        }

        &:nth-of-type(2) {
          grid-column: 5 / span 8;

          @supports not (display: grid) {
            flex: 0 0 66.666%;
          }
        }
      }
    }
  }

  &.grid-container--4-7 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 1 / span 4;

          @supports not (display: grid) {
            flex: 0 0 calc(40% - 15px);
            padding-right: 15px;
          }
        }

        &:nth-of-type(2) {
          grid-column: 6 / span 7;

          @supports not (display: grid) {
            flex: 0 0 calc(60% - 15px);
            padding-right: 15px;
          }
        }
      }
    }
  }

  &.grid-container--4-6 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 2 / span 4;

          @supports not (display: grid) {
            flex: 0 0 calc(30% - 30px);
            padding: 0 15px;
          }
        }

        &:nth-of-type(2) {
          grid-column: 7 / span 6;

          @supports not (display: grid) {
            flex: 0 0 calc(50% - 15px);
            padding-left: 15px;
          }
        }
      }
    }
  }

  &.grid-container--5-7 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 1 / span 5;

          @supports not (display: grid) {
            flex: 0 0 calc(35% - 15px);
            padding-right: 15px;
          }
        }

        &:nth-of-type(2) {
          grid-column: 6 / span 7;

          @supports not (display: grid) {
            flex: 1;
          }
        }
      }
    }
  }

  &.grid-container--1-10 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 1 / span 1;

          @supports not (display: grid) {
            flex: 0 0 20%;
            margin-bottom: 0;
          }
        }

        &:nth-of-type(2) {
          grid-column: 3 / span 10;

          @supports not (display: grid) {
            flex: 0 0 calc(80% - 100px);
            padding-left: 100px;
          }
        }
      }
    }
  }

  &.grid-container--7 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 2 / span 7;

          @supports not (display: grid) {
            flex: 0 0 65%;
            padding-left: 10%;
          }
        }
      }
    }
  }

  &.grid-container--9-3 {
    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 1 / span 9;

          @supports not (display: grid) {
            flex: 0 0 calc(70% - 15px);
            padding-right: 15px;
          }
        }

        &:nth-of-type(2) {
          grid-column: 10 / span 3;

          @supports not (display: grid) {
            flex: 0 0 calc(30% - 15px);
            padding-left: 15px;
          }
        }
      }
    }
  }

  &.grid-container--4-4 {
    @supports not (display: grid) {
      max-width: 834px;
    }

    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 3 / span 4;

          @supports not (display: grid) {
            flex: 0 0 calc(45% - 15px);
            padding-right: 15px;
          }
        }

        &:nth-of-type(2) {
          grid-column: 8 / span 4;

          @supports not (display: grid) {
            flex: 0 0 calc(45% - 15px);
            padding-left: 15px;
          }
        }
      }
    }
  }

  &.grid-container--4-4-alt {
    @supports not (display: grid) {
      max-width: 834px;
    }

    .grid-column {
      grid-column: span 12;

      @include mq(laptop-s) {
        &:nth-of-type(1) {
          grid-column: 3 / span 9;

          @supports not (display: grid) {
            flex: 0 0 auto;
          }
        }
      }
    }
  }
}
