.blog-article-banner__title {
  font-size: 20px;
  line-height: 30px;

  @include mq(laptop-s) {
    font-size: 40px;
    line-height: 40px;
  }
}

.blog-article-details {
  display: flex;
  padding-top: 30px;
}

.blog-article-details__date {
  font-family: $brand-font-secondary;
  @include font-weight(bold);
  font-size: 16px;
  padding-right: 20px;

  span,
  svg {
    vertical-align: middle;
  }

  span {
    display: inline-block;
    padding-left: 5px;
  }
}

.blog-article-details__read-time {
  font-family: $brand-font-secondary;
  @include font-weight(bold);
  font-size: 16px;

  span,
  svg {
    vertical-align: middle;
  }

  span {
    display: inline-block;
    padding-left: 5px;
  }
}

.blog-detail-content {
  background-color: $brand-lilac-dark;
  padding-bottom: 60px;

  .theme-halloween & {
    background-color: $brand-blue-halloween;
  }

  .theme-xmas & {
    background-color: $brand-xmas-red-dark;
  }

  @include mq(laptop-s) {
    padding-bottom: 0;
  }

  .site-wide {
    z-index: $layer-earth;
    position: relative;

    @supports not (display: grid) {
      padding: 0;
    }

    &:before {
      @include mq(laptop-s) {
        width: 100%;
        height: 100%;
        content: '';
        background-color: $brand-blue-dark;
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        transform: translateX(calc(-100% + 30px));

        .theme-halloween & {
          background-color: $brand-orange;
        }

        .theme-xmas & {
          background-color: $brand-xmas-green-dark;
        }

        @supports not (display: grid) {
          transform: translateX(-100%);
        }
      }
    }
  }
}

.blog-detail-content__holder {
  background-color: $brand-white;
  padding: 20px;
  width: 100%;

  @include mq(laptop-s) {
    padding: 70px 100px;
  }
}

.blog-detail-content__holder-inner {
  max-width: 670px;

  .large-text {
    @include font-weight(bold);
  }

  p:not(.large-text) {
    margin-bottom: 40px;
  }

  .content-meta {
    margin-top: 24px;

    span {
      font-weight: 700;
      font-size: 16px;
      padding-right: 20px;
      margin-bottom: 8px;
    }
  }

  .wysiwyg {
    padding: 0;
  }

  .site-wide {
    padding: 0;

    &:before {
      content: none;
    }
  }
}

.blog-detail-content__buffer-top {
  display: none;
  height: 10px;

  @include mq(laptop-s) {
    display: grid;
  }

  .grid-column {
    &:first-child {
      background-color: $brand-blue-dark;

      .theme-halloween & {
        background-color: $brand-orange;
      }

      .theme-xmas & {
        background-color: $brand-xmas-green-dark;
      }
    }
  }
}

.blog-detail-content__buffer-base {
  display: none;
  height: 120px;

  @include mq(laptop-s) {
    display: grid;
  }

  .grid-column {
    &:first-child {
      background-color: $brand-blue-dark;

      .theme-halloween & {
        background-color: $brand-orange;
      }

      .theme-xmas & {
        background-color: $brand-xmas-green-dark;
      }
    }
  }
}
