.app-or-tap {
  max-width: 394px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.app-or-tap__inner {
  display: grid;
  grid-template-columns: 44px auto;
  gap: 16px;

  @include mq(tablet) {
    grid-template-columns: 74px auto;
  }
}

.app-or-tap__left-col {
}

.app-or-tap__right-col {
}

.app-or-tap__logo {
  width: 44px;
  height: 44px;

  @include mq(tablet) {
    width: 74px;
    height: 74px;
  }
}

.app-or-tap__title {
  font-family: $brand-font-secondary;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 700;

  @include mq(tablet) {
    margin-bottom: 8px;
  }
}

.app-or-tap__content {
  font-family: $brand-font-secondary;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 34px;

  @include mq(tablet) {
    margin-bottom: 24px;
  }
}

.app-or-tap__link-area {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-bottom: 49px;

  @include mq(tablet) {
    gap: 24px;
    margin-bottom: 40px;
  }
}

.app-or-tap__link {
  display: flex;
  background-color: $brand-white;
  text-decoration: none;
  color: $brand-black;
  min-height: 42px;
  align-items: center;
  font-family: $brand-font-secondary;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 183px;

  @include mq(tablet) {
    width: 80%;
  }

  span {
    display: inline-flex;
    margin-right: 16px;
    height: 100%;
    width: 42px;
    justify-content: center;
    align-items: center;

    img {
      align-self: center;
    }
  }

  .purple-bg {
    background-color: $brand-lilac-dark;
  }

  .blue-bg {
    background-color: $brand-blue-light;
  }
}

.app-or-tap__download-title {
  font-family: $brand-font-secondary;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
}

.app-or-tap__download-area {
  .form__download {
    flex-direction: column;
    gap: 24px;
  }
}

.app-or-tap__download-area__desktop {
  display: none;

  @include mq(tablet) {
    display: block;
  }
}

.app-or-tap__qr-image {
  width: 212px;
  height: 212px;
}

.app-or-tap__download-area__mobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @include mq(tablet) {
    display: none;
  }
}

.app-or-tap__store-link {
  max-width: 141px;
}

.app-or-tap__app-btn {
}
