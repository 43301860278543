@mixin helpers-reset-button {
  border: 0;
  background: transparent;
  font-weight: normal;
  padding: 0;
  outline: 0;
}

@mixin helpers-reset-hover-link {
  &:hover {
    text-decoration: none;
  }
}

@mixin helpers-burger(
  $width: 30px,
  $height: 5px,
  $gutter: 3px,
  $color: #000,
  $border-radius: 0,
  $transition-duration: 0.3s
) {
  $burger-height: $height !global;
  $burger-gutter: $gutter !global;

  position: relative;
  margin-top: $height + $gutter;
  margin-bottom: $height + $gutter;

  &,
  &:before,
  &:after {
    display: block;
    width: $width;
    height: $height;
    background-color: $color;

    @if $border-radius != 0 {
      border-radius: $border-radius;
    }

    transition-property: background-color, transform;
    transition-duration: $transition-duration;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
  }

  &:before {
    top: -($height + $gutter);
  }

  &:after {
    top: $height + $gutter;
  }
}

@mixin helpers-burger-to-cross {
  & {
    background-color: transparent;
  }

  &:before {
    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }
  &:after {
    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}

@mixin helpers-fa-icon($icon-var) {
  @include fa-icon();

  &:before {
    content: $icon-var;
  }
}

// Set font weight value.
@mixin font-weight($weight) {
  font-weight: map-get($font-weight, $weight);
}

// Reset list styles.
@mixin helpers-reset-list($mb: null) {
  list-style: none;
  padding: 0;
  @if $mb != null {
    margin-bottom: $mb;
  } @else {
    margin: 0;
  }
}

// Inline list.
@mixin helpers-inline-list($va: top) {
  li {
    display: inline-block;
    vertical-align: $va;
  }
}

// Set letter-spacing based on Adobe XD character spacing value.
@mixin letter-spacing($value) {
  letter-spacing: netc-character-spacing($value);
}

@mixin reste-ms-input {
  &::-ms-clear {
    display: none;
  }
}

@mixin netc-font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin object-fit($of: cover, $op: top) {
  width: 100%;
  height: 100%;
  object-fit: $of;
  object-position: $op;
  font-family: 'object-fit: #{$of}; object-position: #{$op;}';
}
