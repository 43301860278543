.advertising-block {
  width: 100%;
  max-width: 728px;

  img {
    display: block;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
    max-height: 25vh;
  }
}
