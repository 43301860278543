$brand-black: #0a0a0a;
$brand-white: #ffffff;

$brand-blue-dark: #2e3091;
$brand-blue-light: #47a8f2;
$brand-lilac-dark: #cfa6ff;
$brand-lilac-light: #f8f2ff;
$brand-washer: #70cf4e;
$brand-dryer: #70cf4e;
$brand-unknown: #e6e6e6;

$brand-grey: #f4f4f4;
$brand-grey-dark: #acacac;
$brand-yellow: #f7d100;
$brand-red: #ed1c24;

$brand-text: $brand-black;

// OLD COLOURS - DELETE AFTER SITE UPDATED
$brand-pink-dark: #41cc47;
$brand-pink-light: #41cc47;
$brand-pink-light-alt: #41cc47;
$brand-green: #41cc47;
$brand-slate-dark: #41cc47;
$brand-slate-light: #41cc47;

// HALLOWEEN COLOURS
$brand-orange: #fc962e;
$brand-orange-light: #ffbb4d;
$brand-orange-dark: #e86807;
$brand-orange-bright: #ff5e35;
$brand-purple: #5e1742;
$brand-purple-dark: #330136;
$brand-blue-halloween: #012641;
$brand-red-dark: #962903;
$brand-grey-halloween: #b5b5b5;

//XMAS COLOURS
$brand-charcoal: #333333;
$brand-xmas-blue-light: #a9d6e5;
$brand-xmas-red: #d83848;
$brand-xmas-red-dark: #b22222;
$brand-xmas-green: #2e8b57;
$brand-xmas-green-dark: #145a3a;
$brand-snow-white: #f5f9ff;
$brand-xmas-blue-dark: #133a4d;
$brand-xmas-blue-twilight: #1a4b6e;
