@import '~sass-mq/mq';

// sass-mq configuration - please see https://github.com/sass-mq/sass-mq for more infos
// Enable media queries support
$mq-responsive: true;

// Base font size
$mq-base-font-size: 18px !default;

// Defines breakpoints list
$mq-breakpoints: (
  mobile: 375px,
  mobile-m: 430px,
  mobile-l: 550px,
  tablet: 768px,
  laptop-s: 1024px,
  laptop-m: 1122px,
  desktop: 1366px,
  desktop-l: 1400px,
  desktop-xl: 1520px,
  desktop-xxl: 1800px,
  nav: 1070px,
  footer: 980px,
);

// Padding on either side of the main container.
$site-wide-padding: 30px;

// Width of the main container.
$desktop-width: map-get($mq-breakpoints, 'laptop-m');
$site-width: $desktop-width + ($site-wide-padding * 2);
