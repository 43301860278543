.btn,
input[type='submit'].btn {
  @include helpers-reset-button;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  border: 2px solid $brand-black;
  border-radius: 20px;
  font-family: $brand-font-secondary;
  @include font-weight(bold);
  font-size: 16px;
  text-decoration: none;
  background-color: $brand-black;
  color: $brand-white;
  text-align: center;
  text-transform: uppercase;
  @include letter-spacing(50);
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out,
    color 0.3s ease-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  svg {
    stroke: $brand-white;
    margin-left: 11px;
    transition: stroke 0.3s ease-out;
  }

  &:hover {
    background-color: $brand-white;
    color: $brand-black;

    svg {
      stroke: $brand-black;
    }
  }

  &.btn--secondary {
    border-color: $brand-white;
    background-color: $brand-white;
    color: $brand-black;

    svg {
      stroke: $brand-black;
    }

    &:hover {
      background-color: $brand-black;
      color: $brand-white;
      border-color: $brand-black;

      svg {
        stroke: $brand-white;
      }
    }

    &.btn--border {
      border: 2px solid $brand-black;
    }
  }

  &.btn--seasonal {
    background-color: $brand-orange-light;
    border: 0;
    color: $brand-black;
    border: 1px solid $brand-white;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;

    .theme-xmas & {
      background-color: $brand-xmas-red;
      border-color: $brand-snow-white;
      color: $brand-snow-white;

      svg {
        stroke: $brand-snow-white;
      }

      &:hover {
        border-color: $brand-xmas-red;
        background-color: $brand-snow-white;
        color: $brand-xmas-red;

        svg {
          stroke: $brand-xmas-red;
        }
      }
    }

    svg {
      stroke: $brand-black;
    }

    &:hover {
      border-color: $brand-white;
      background-color: $brand-black;
      color: $brand-orange-light;

      svg {
        stroke: $brand-orange-light;
      }
    }
  }

  &.btn--seasonal-secondary {
    background-color: $brand-white;
    border: 0;
    color: $brand-black;
    border: 1px solid $brand-white;
    transition: background-color 0.3s, color 0.3s;

    .theme-xmas & {
      background-color: $brand-snow-white;
      border-color: $brand-snow-white;
      color: $brand-xmas-blue-twilight;

      svg {
        stroke: $brand-xmas-blue-twilight;
      }

      &:hover {
        border-color: $brand-snow-white;
        background-color: $brand-xmas-blue-twilight;
        color: $brand-snow-white;

        svg {
          stroke: $brand-snow-white;
        }
      }
    }

    svg {
      stroke: $brand-black;
    }

    &:hover {
      background-color: $brand-blue-halloween;
      color: $brand-white;

      svg {
        stroke: $brand-white;
      }
    }
  }

  &.btn--seasonal-tertiary {
    background-color: $brand-xmas-green;
    border: 0;
    color: $brand-snow-white;
    border: 1px solid $brand-snow-white;
    transition: background-color 0.3s, color 0.3s;

    svg {
      stroke: $brand-snow-white;
    }

    &:hover {
      border-color: $brand-xmas-green;
      background-color: $brand-snow-white;
      color: $brand-xmas-green;

      svg {
        stroke: $brand-xmas-green;
      }
    }
  }

  &.btn--paypal {
    background-color: #ffc43a;
    border: 0;
    color: $brand-black;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 2px solid #ffc43a;
    transition: border-color 0.3s ease-out;

    &:hover {
      border-color: $brand-black;
    }

    svg {
      width: 90px;
      display: block !important; // needed unfortunately
      margin-right: 5px;
      margin-left: 0;
    }
  }

  &.btn--freedom {
    font-size: 14px;
    background-color: #11b0bf;
    border: 0;
    color: $brand-black;
    padding: 10px 14px;
    border: 2px solid #11b0bf;
    transition: border-color 0.3s ease-out;

    svg {
      width: 112px;
      margin-right: 5px;
      margin-left: 0;
      stroke: none;
    }

    &:hover {
      border-color: $brand-black;

      svg {
        stroke: none;
      }
    }
  }

  &--mob-only {
    display: inline-flex;

    @include mq(tablet) {
      display: none;
    }
  }

  &--desktop-only {
    display: none;

    @include mq(tablet) {
      display: inline-flex;
    }
  }
}

.btn-text-only {
  @include helpers-reset-button;
  display: flex;
  align-items: center;
  font-size: 22px;
  color: $brand-white;
  text-transform: uppercase;
  font-family: $brand-font-secondary;
  @include font-weight(bold);
  @include letter-spacing(20);
  text-decoration: none;
  position: relative;

  .theme-halloween & {
    &:before {
      background-color: $brand-purple-dark;
    }
  }

  .theme-xmas & {
    color: $brand-xmas-blue-dark;

    &:before {
      background-color: $brand-snow-white;
    }
  }

  &:before {
    width: 50px;
    height: 10px;
    content: '';
    position: absolute;
    background-color: $brand-blue-dark;
    transform: scaleX(1);
    transform-origin: center right;
    transition: transform 0.4s ease-in-out;
  }

  &:hover {
    svg {
      fill: $brand-white;

      .theme-halloween & {
        fill: $brand-orange;
      }
    }

    &:before {
      transform: scaleX(0);
    }
  }

  svg {
    margin-right: 15px;
    transition: opacity 0.4s ease-in-out, fill 0.4s ease-in-out;
    fill: $brand-blue-light;

    .theme-halloween & {
      fill: $brand-orange;
    }

    .theme-xmas & {
      fill: $brand-xmas-blue-dark;
    }
  }

  + .btn-text-only {
    margin-top: 30px;
  }

  &.is-active {
    color: $brand-blue-light;

    .theme-halloween & {
      color: $brand-orange;
    }

    .theme-xmas & {
      color: $brand-xmas-red-dark;
    }

    svg {
      .theme-xmas & {
        fill: $brand-xmas-red-dark;
      }
    }

    &:before {
      transform: scaleX(0);
    }

    &:hover {
      svg {
        fill: $brand-blue-light;

        .theme-halloween & {
          fill: $brand-orange-light;
        }

        .theme-xmas & {
          fill: $brand-xmas-red-dark;
        }
      }
    }
  }
}

.btn-alt,
.btn-close {
  @include helpers-reset-button;
}
