/**
 * Add typography variables in this file.
 */

$brand-font-primary: 'Rubik Mono One', sans-serif;
$brand-font-secondary: 'Rubik', sans-serif;
$brand-font-halloween: 'Creepster', sans-serif;
$brand-font-xmas: 'Lobster Two', sans-serif;

// Font weight map
$font-weight: (
  regular: 400,
  medium: 500,
  bold: 700,
);
