.accordions {
  max-height: 300px;
  overflow: auto;

  .accordion {
    max-height: 55px;
    padding-left: 4px;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;

    .accordion__slug {
      display: flex;
      align-items: center;
      background-color: $brand-white;
      padding-top: 7px;
      padding-right: 10px;
      padding-bottom: 6px;
      padding-left: 10px;
      margin-right: 15px;
      margin-bottom: 20px;
      border-radius: 20px;
      box-shadow: 0 3px 6px rgba($brand-black, 0.16);

      &.accordion__slug--in-use {
        background-color: $brand-pink-light-alt;
      }

      &.accordion__slug--idle {
        background-color: $brand-yellow;
      }

      &.accordion__slug--unknown {
        background-color: $brand-grey-dark;
      }

      .accordion__icon {
        width: 19px;
        height: 19px;
        min-width: 19px;
        border: 2px solid $brand-blue-dark;
        border-radius: 19px;
        margin-left: 6px;
        position: relative;

        &:before,
        &:after {
          width: 8px;
          height: 2px;
          content: '';
          background-color: $brand-blue-dark;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: transform 0.4s ease-in-out;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      .appliance {
        @include font-weight(bold);

        font-size: 15px;

        @include mq($until: mobile-m) {
          display: flex;
          align-items: center;
        }

        span {
          @include mq($until: mobile-m) {
            width: 22px;
            height: 22px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 22px;
            background-color: $brand-washer;
            color: $brand-white;
            font-size: 10px;
            margin-right: 3px;
          }

          span {
            @include mq($until: mobile-m) {
              display: none;
            }
          }
        }

        &.dryer {
          span {
            @include mq($until: mobile-m) {
              background-color: $brand-dryer;
            }
          }
        }
      }

      .time-remaining {
        @include font-weight(semibold);

        display: flex;
        align-items: center;
        font-size: 13px;
        font-style: italic;
        margin-left: auto;

        .icon-svg {
          width: 10px;
          height: 10px;
          margin-right: 3px;

          @include mq($until: mobile) {
            display: none;
          }
        }
      }
    }

    p {
      @include font-weight(semibold);

      font-size: 14px;
      padding-right: 11px;
      padding-left: 11px;

      strong {
        @include font-weight(bold);
      }
    }

    &.is-open {
      max-height: 100%;

      .accordion__icon {
        &:before {
          transform: translate(-50%, -50%) rotate(0);
        }
      }
    }
  }
}

.accordions--2020 {
  max-height: 100%;

  @include mq($until: laptop-s) {
    overflow: visible;
  }

  .accordion {
    max-height: 95px;
    background-color: transparent;
    padding-left: 0;
    border-top: 4px solid $brand-white;
    transition: border-color 0.4s ease-in-out;

    .faqs--no-padding & {
      @include mq($until: laptop-s) {
        padding-left: 10px;
      }
    }

    &:last-child {
      border-bottom: 4px solid $brand-white;
    }

    &__title {
      padding-right: 35px;
    }

    .accordion__slug {
      height: 95px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      margin: 0;
      background-color: transparent;
      color: $brand-white;
      font-size: 18px;
      @include font-weight(bold);
      text-transform: uppercase;

      &-icon {
        position: relative;

        @include mq($until: laptop-s) {
          width: 10px;
        }

        svg {
          position: absolute;
          top: -5px;
          right: 17px;
          transition: transform 0.4s ease-in-out;
          stroke: $brand-white;
        }
      }
    }

    p:not(.small-text) {
      padding: 0;
      font-size: 16px;
      color: $brand-white;
      margin-bottom: 15px;
      transition: transform 0.4s ease-in-out;
    }

    .small-text {
      font-size: 12px;
      color: $brand-blue-light;
      text-transform: uppercase;
      padding: 0;
      margin: 0;

      .theme-halloween & {
        color: $brand-orange;
      }

      .theme-xmas & {
        color: $brand-xmas-red-dark;
      }
    }

    &.is-open {
      max-height: 100%;
      border-color: $brand-lilac-dark;

      + .accordion {
        border-top-color: $brand-lilac-dark;
      }

      .accordion__slug-icon {
        svg:first-child {
          transform: translateY(3px) rotate3d(1, 0, 0, 180deg);
        }

        svg:last-child {
          transform: translateY(-3px);
        }
      }

      > p:not(.small-text) {
        transform: translateY(-15px);
      }
    }
  }

  &.accordions--alt {
    @include mq(laptop-s) {
      > .accordion {
        border: 0;

        .accordion__slug {
          display: none;
        }
      }
    }

    .accordion.is-open {
      + .accordions__nested {
        .accordion:first-child {
          border-color: $brand-blue-light;

          .theme-halloween & {
            border-color: $brand-orange;
          }

          .theme-xmas & {
            border-color: $brand-xmas-red-dark;
          }
        }

        .accordion:last-child {
          border-bottom-color: $brand-blue-light;

          .theme-halloween & {
            border-bottom-color: $brand-orange;
          }

          .theme-xmas & {
            border-bottom-color: $brand-xmas-red-dark;
          }
        }
      }

      &:first-child {
        + .accordions__nested {
          .accordion:first-child {
            border-color: $brand-blue-light;

            .theme-halloween & {
              border-color: $brand-orange;
            }

            .theme-xmas & {
              border-color: $brand-xmas-red-dark;
            }
          }
        }
      }
    }

    .accordion {
      border-color: $brand-black;
      max-height: 104px;

      .theme-halloween & {
        border-color: $brand-white;
      }

      .theme-xmas & {
        border-color: $brand-xmas-blue-dark;
      }

      .accordion__slug,
      p:not(.small-text) {
        color: $brand-black;

        .theme-halloween & {
          color: $brand-white;
        }

        .theme-xmas & {
          color: $brand-xmas-blue-dark;
        }

        @include mq($until: laptop-s) {
          color: $brand-white;
        }
      }

      ul {
        li {
          color: $brand-black;

          .theme-halloween & {
            color: $brand-white;
          }

          .theme-xmas & {
            color: $brand-xmas-blue-dark;
          }

          @include mq($until: laptop-s) {
            color: $brand-white;
          }
        }
      }

      svg {
        stroke: $brand-black;

        .theme-halloween & {
          stroke: $brand-white;
        }

        .theme-xmas & {
          stroke: $brand-xmas-blue-dark;
        }

        @include mq($until: laptop-s) {
          stroke: $brand-white;
        }
      }

      &.is-open {
        max-height: 100%;
        border-color: $brand-blue-light;
        position: relative;
        z-index: $layer-earth;

        .theme-halloween & {
          border-color: $brand-orange;
        }

        .theme-xmas & {
          border-color: $brand-xmas-red-dark;
        }

        + .accordion {
          border-top-color: $brand-blue-light;

          .theme-halloween & {
            border-top-color: $brand-orange;
          }

          .theme-xmas & {
            border-top-color: $brand-xmas-red-dark;
          }
        }
      }
    }
  }

  .accordions__nested + .accordion {
    @include mq($until: laptop-s) {
      border-bottom: 4px solid $brand-black;
    }
  }

  .accordions__nested + .accordion + .accordions__nested {
    margin-top: -4px;
  }

  .accordion:not(.accordion--alt) {
    background-color: $brand-blue-dark;

    .theme-halloween &,
    .theme-xmas & {
      background-color: transparent;
    }

    @include mq($until: laptop-s) {
      border-color: $brand-white;
    }

    + .accordions__nested {
      @include mq($until: laptop-s) {
        max-height: 0;
        transform: scaleY(0);
        transform-origin: top center;
        overflow: hidden;
        transition: max-height 0.4s ease-in-out, transform 0.4s ease-in-out;
      }
    }

    &.is-open {
      + .accordions__nested {
        transform: scaleY(1);
        max-height: 100%;

        + .accordion {
          border-top: 0;
        }
      }
    }

    .accordion__slug {
      color: $brand-white;

      &-icon {
        svg {
          stroke: $brand-white;
        }
      }
    }
  }

  &.accordions--circuit-view {
    .accordion {
      border: 0;
      background-color: transparent;

      &__title,
      p {
        color: $brand-black;
      }
    }
  }
}
