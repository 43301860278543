.error-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $brand-lilac-dark;

  &__waves {
    width: 100%;
    height: 332px;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('../img/svg/rebrand-2020/icon-waves-hero.svg');

    &--bottom {
      top: 100%;
      transform: translateY(-100%);
    }
  }

  &__inner {
    padding: 50px;
    z-index: $layer-earth;
    text-align: center;

    h1 {
      font-size: 60px;

      + svg {
        margin-top: 20px;
      }
    }

    .btn {
      display: block;
      margin: 20px auto 0;
    }

    a.btn {
      display: inline-block;
    }
  }
}
